import React from "react";
import MenuButton from "../MenuButton/MenuButton";
import { useFormik } from "formik";
import { salaryFilter } from "../schemas";
import useTable from "../../hooks/useTable";
import useSortableData from "../../hooks/useSortableData";
import Pagination from "react-js-pagination";
import { useState } from "react";
import UserServices from "../../UserServices/UserServices";
import { useEffect } from "react";
import { useContext } from "react";
import { NoteState } from "../../context/notes/NoteState";
import CurrencyFormat from "react-currency-format";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import jsPDF from "jspdf";
import $ from "jquery";
import moment from "moment";

const StaffCommision = () => {
  const { state } = useContext(NoteState);
  const { branch_id, role, UId } = state;

  // Get the current date
  // const currentDate = new Date();

  // currentDate.setDate(1);

  // const year = currentDate.getFullYear();
  // const month = currentDate.getMonth() + 1;

  // const formattedMonth = month < 10 ? `0${month}` : month;
  const currentDate = new Date();
  currentDate.setDate(1);

  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1;

  const formattedMonth = month < 10 ? `0${month}` : month;

  // Calculate the start date by subtracting one month
  const previousMonth = month === 1 ? 12 : month - 1;
  const previousYear = month === 1 ? year - 1 : year;
  const formattedPreviousMonth =
    previousMonth < 10 ? `0${previousMonth}` : previousMonth;
  const startDate = `${previousYear}-${formattedPreviousMonth}-26`;

  const [loading, setLoading] = useState(false);
  const [myData, setMyData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);

  const [limit, setLimit] = useState("25");
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [exportLink, setExportLink] = useState("");
  const [salesTax, setSalesTax] = useState(10);
  const [trainerName, setTrainerName] = useState([]);

  // const getSalary = async (e) => {
  //   if (Date.parse(values.sdate) <= Date.parse(values.edate)) {
  //     setLoading(true);
  //     try {
  //       const responce = await UserServices.getLatestStaffCommision(
  //         branch_id,
  //         values,
  //         limit,
  //         "!"
  //       );
  //       let myDataarr;

  //       if (responce.status === 200) {
  //         setExportLink(
  //           process.env.REACT_APP_API_Link +
  //             "/auth/export-salaries?branch_id=" +
  //             branch_id +
  //             "&start_date=" +
  //             values.edate +
  //             "-" +
  //             values.sdate +
  //             "-01" +
  //             "&end_date=" +
  //             values.edate +
  //             "-" +
  //             values.sdate +
  //             "-31"
  //         );
  //         myDataarr = responce.data.data;
  //         const res = myDataarr;
  //         const results = [];

  //         res.map((values) => {
  //           results.push({
  //             id: values.id,
  //             name: values.name,
  //             department: values.department,
  //             designation: values.designation,
  //             commission: parseInt(values.commission?.commission),
  //             commission_per: parseInt(values.commission?.commission_per),
  //             gx_commission: parseInt(values.commission?.gx_commission),
  //             pt_commission: parseInt(values.commission?.pt_commission),
  //             pt_commission: parseInt(values.commission?.pt_commission),
  //             small_pt_commission: parseInt(
  //               values.commission?.small_pt_commission
  //             ),
  //             details: values.commission?.details,
  //           });
  //           return null;
  //         });

  //         setMyData([...results]);
  //         setLimit(responce.data.pagination.per_page);
  //         setTotalResults(responce.data.pagination.total_record);
  //         setTotalPages(responce.data.pagination.total_pages);
  //         setCurrentPage(responce.data.pagination.current_page);
  //         setLoading(false);
  //       }
  //     } catch (err) {
  //       if (err.response.status === 404) {
  //         setExportLink();
  //         setTotalResults("0");
  //         setTotalPages("1");
  //         setMyData([]);
  //         setLoading(false);
  //       } else {
  //         setLoading(false);
  //       }
  //     }
  //   } else {
  //     Swal.fire({
  //       icon: "error",
  //       title: "Oops...",
  //       text: "End date should be greater then start date!",
  //     });
  //   }
  // };
  //adeel added download button for commision pdf
  const generatePdf = (table) => {
    const doc = new jsPDF("p", "mm", "letter");
    const captionText = table.getAttribute("data-caption");
    doc.text(captionText, 10, 10);
    const tableOptions = {
      headStyles: { fillColor: [232, 0, 43], textColor: 255 },
      styles: { fontSize: 5 },
    };
    doc.autoTable({
      html: table,
      ...tableOptions,
    });
    doc.save("staff-commission.pdf");
  };
  //adeel

  const getSalary = async (e) => {
    if (Date.parse(values.startdate) <= Date.parse(values.enddate)) {
      setLoading(true);
      try {
        const response = await UserServices.getLatestStaffCommision(
          branch_id,
          values,
          limit,
          "!"
        );
        let myDataarr;

        if (response.status === 200) {
          setExportLink(
            process.env.REACT_APP_API_Link +
            "/auth/export-salaries?branch_id=" +
            branch_id +
            "&start_date=" +
            values.startdate +
            "&end_date=" +
            values.enddate
          );
          myDataarr = response.data.data;
          const res = myDataarr;
          const results = [];

          res.map((values) => {
            results.push({
              id: values.id,
              name: values.name,
              department: values.department,
              designation: values.designation,
              commission: parseInt(values.commission?.commission),
              commission_per: parseInt(values.commission?.commission_per),
              gx_commission: parseInt(values.commission?.gx_commission),
              pt_commission: parseInt(values.commission?.pt_commission),
              small_pt_commission: parseInt(
                values.commission?.small_pt_commission
              ),
              details: values.commission?.details,
            });
            return null;
          });

          setMyData([...results]);
          setLimit(response.data.pagination.per_page);
          setTotalResults(response.data.pagination.total_record);
          setTotalPages(response.data.pagination.total_pages);
          setCurrentPage(response.data.pagination.current_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setExportLink();
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "End date should be greater than start date!",
      });
    }
  };

  const getSalesTax = async (e) => {
    setLoading(true);
    try {
      const responce = await UserServices.getTaxy(branch_id, "Sales Tax");

      if (responce.status === 200) {
        setSalesTax(responce.data.data.fee);
        setLoading(false);
      }
    } catch (err) {
      if (err?.response?.status === 404) {
        setSalesTax(10);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  const gymTrainer = async (br_Id) => {
    // var br_Id = e.target.value;
    try {
      const responce = await UserServices.TrainerName(br_Id);

      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          first_name: value.first_name,
          last_name: value.last_name,
        });
        return null;
      });
      setTrainerName([{ first_name: "Select Trainer", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
      setTrainerName([]);
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getSalary();
    getSalesTax();
    gymTrainer(branch_id);
  }, []);

  // const initialValues = {
  //   sdate: formattedMonth,
  //   edate: year,
  //   trainer_id: role === "9" ? UId : "",
  // };
  const initialValues = {
    sdate: formattedMonth,
    edate: year,
    trainer_id: role === "9" ? UId : "",
    startdate: startDate,
    enddate: `${year}-${formattedMonth}-25`,
  };
  //.......................................................................................................................
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: salaryFilter,
    onSubmit: (values) => {
      getSalary();
    },
  });

  const changePagination = (e) => {
    setCurrentPage(e);
    let pageNo = e;
    const getPaginationData = async () => {
      setLoading(true);

      try {
        const responce = await UserServices.getLatestStaffSalary(
          branch_id,
          values,
          limit,
          pageNo
        );

        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data;
          const res = myDataarr;
          const results = [];

          res.map((values) => {
            results.push({
              id: values.id,
              name: values.name,
              department: values.department,
              designation: values.designation,
              commission: parseInt(values.commission?.commission),
              commission_per: parseInt(values.commission?.commission_per),
              gx_commission: parseInt(values.commission?.gx_commission),
              pt_commission: parseInt(values.commission?.pt_commission),
              pt_commission: parseInt(values.commission?.pt_commission),
              small_pt_commission: parseInt(
                values.commission?.small_pt_commission
              ),
              details: values.commission?.details,
            });
            return null;
          });

          setMyData([...results]);
          setLimit(responce.data.pagination.per_page);
          setTotalResults(responce.data.pagination.total_record);
          setTotalPages(responce.data.pagination.total_pages);
          setCurrentPage(responce.data.pagination.current_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");

          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getPaginationData();
  };
  const changeLimit = (e) => {
    let newLimit = e;
    setLimit(newLimit);
    const getLimitData = async () => {
      setLoading(true);

      try {
        const responce = await UserServices.getLatestStaffSalary(
          branch_id,
          values,
          newLimit,
          "!"
        );

        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data;
          const res = myDataarr;
          const results = [];

          res.map((values) => {
            results.push({
              id: values.id,
              name: values.name,
              department: values.department,
              designation: values.designation,
              commission: parseInt(values.commission?.commission),
              commission_per: parseInt(values.commission?.commission_per),
              gx_commission: parseInt(values.commission?.gx_commission),
              pt_commission: parseInt(values.commission?.pt_commission),
              pt_commission: parseInt(values.commission?.pt_commission),
              small_pt_commission: parseInt(
                values.commission?.small_pt_commission
              ),
              details: values.commission.length
                ? values.commission?.details
                : [],
            });
            return null;
          });

          setMyData([...results]);
          setLimit(responce.data.pagination.per_page);
          setTotalResults(responce.data.pagination.total_record);
          setTotalPages(responce.data.pagination.total_pages);
          setCurrentPage(responce.data.pagination.current_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");

          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getLimitData();
  };
  const showInstalDetails = (id) => {
    $("#" + id).fadeToggle();
  };

  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <div className="table-responsive mt-3">
          <table
            className="table table-striped table-borderless table-style-1"
            id="report-POS"
            data-caption="Staff Commission"
          >
            <thead>
              <tr>
                <th scope="col" className="no_sort">
                  Sr#
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("name")}
                  className={getClassNamesFor("name")}
                >
                  Staff Name
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("department")}
                  className={getClassNamesFor("department")}
                >
                  Department
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("designation")}
                  className={getClassNamesFor("designation")}
                >
                  Designation
                </th>

                <th
                  scope="col"
                  onClick={() => requestSort("commission_per")}
                  className={getClassNamesFor("commission_per")}
                >
                  PT %
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("pt_commission")}
                  className={getClassNamesFor("pt_commission")}
                >
                  PT Commission
                </th>

                <th
                  scope="col"
                  onClick={() => requestSort("gx_commission")}
                  className={getClassNamesFor("gx_commission")}
                >
                  Studio Commission
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("small_pt_commission")}
                  className={getClassNamesFor("small_pt_commission")}
                >
                  SPT Commission
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("commission")}
                  className={getClassNamesFor("commission")}
                >
                  Total Commission
                </th>
              </tr>
            </thead>
            <tbody>
              {items.length ? (
                items.map((el, index) => (
                  <>
                    <tr key={index}>
                      <td>
                        <div className="d-flex">
                          <span className="ms-2">
                            {currentPage === 1
                              ? index + 1
                              : (currentPage - 1) * limit + index + 1}
                          </span>
                          {el.details?.length ? (
                            <div
                              className="ms-2"
                              onClick={() => {
                                showInstalDetails(el.id);
                              }}
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              <i className="bi bi-chevron-down fw-bold"></i>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </td>
                      <td>
                        <Link to={"/staff-profile/" + el.id}>{el.name}</Link>
                      </td>
                      <td>{el.department}</td>

                      <td>{el.designation}</td>

                      <td>
                        {/* {el.commission_per + "%"} */}
                        <CurrencyFormat
                          value={el.commission_per ? el.commission_per : "0"}
                          displayType={"text"}
                          thousandSpacing={"2s"}
                          thousandSeparator={true}
                          suffix={"%"}
                          renderText={(value) => <span>{value}</span>}
                        />
                      </td>
                      <td>
                        <CurrencyFormat
                          value={el.pt_commission ? el.pt_commission : "0"}
                          displayType={"text"}
                          thousandSpacing={"2s"}
                          thousandSeparator={true}
                          prefix={"Rs "}
                          suffix={"/-"}
                          renderText={(value) => <span>{value}</span>}
                        />
                      </td>

                      <td>
                        <CurrencyFormat
                          value={el.gx_commission ? el.gx_commission : "0"}
                          displayType={"text"}
                          thousandSpacing={"2s"}
                          thousandSeparator={true}
                          prefix={"Rs "}
                          suffix={"/-"}
                          renderText={(value) => <span>{value}</span>}
                        />
                      </td>
                      <td>
                        <CurrencyFormat
                          value={
                            el.small_pt_commission
                              ? el.small_pt_commission
                              : "0"
                          }
                          displayType={"text"}
                          thousandSpacing={"2s"}
                          thousandSeparator={true}
                          prefix={"Rs "}
                          suffix={"/-"}
                          renderText={(value) => <span>{value}</span>}
                        />
                      </td>
                      <td>
                        <CurrencyFormat
                          value={el.commission ? el.commission : "0"}
                          displayType={"text"}
                          thousandSpacing={"2s"}
                          thousandSeparator={true}
                          prefix={"Rs "}
                          suffix={"/-"}
                          renderText={(value) => <span>{value}</span>}
                        />
                      </td>
                    </tr>
                    {el.details?.length ? (
                      <tr
                        className="instalmnt-info table table-striped"
                        id={el.id}
                      >
                        <td colSpan="9">
                          <tr>
                            <th
                              style={{
                                width: "13%",
                                background: "rgb(252 239 239);",
                                color: "#000",
                              }}
                            >
                              Client Name
                            </th>
                            <th
                              style={{
                                width: "13%",
                                background: "rgb(252 239 239);",
                                color: "#000",
                              }}
                            >
                              Contract Detail
                            </th>
                            <th
                              style={{
                                width: "13%",
                                background: "rgb(252 239 239);",
                                color: "#000",
                              }}
                            >
                              Date Of Joining
                            </th>
                            <th
                              style={{
                                background: "rgb(252 239 239);",
                                color: "#000",
                                width: "13%",
                              }}
                            >
                              Date Of Expiry
                            </th>

                            <th
                              style={{
                                background: "rgb(252 239 239);",
                                color: "#000",
                                width: "13%",
                              }}
                            >
                              Session Conducted
                            </th>
                            <th
                              style={{
                                background: "rgb(252 239 239);",
                                color: "#000",
                                width: "9%",
                              }}
                            >
                              PKG
                            </th>
                            <th
                              style={{
                                background: "rgb(252 239 239);",
                                color: "#000",
                                width: "13%",
                              }}
                            >
                              Per Session
                            </th>
                            <th
                              style={{
                                background: "rgb(252 239 239);",
                                color: "#000",
                                width: "13%",
                              }}
                            >
                              Total Commission
                            </th>
                            <th
                              style={{
                                background: "rgb(252 239 239);",
                                color: "#000",
                                width: "13%",
                              }}
                            >
                              No Show Count
                            </th>
                            <th
                              style={{
                                background: "rgb(252 239 239);",
                                color: "#000",
                                width: "13%",
                              }}
                            >
                              Status
                            </th>
                            <th
                              style={{
                                background: "rgb(252 239 239);",
                                color: "#000",
                                width: "13%",
                              }}
                            >
                              Remarks
                            </th>
                          </tr>
                          {el.details?.map((inl, index) => (
                            <tr key={index}>
                              <td>{inl.client_name}</td>
                              <td>{inl.contract_detail}</td>

                              <td>
                                {moment(inl.date_of_joining).format(
                                  "DD-MM-YYYY"
                                )}
                              </td>
                              <td>
                                {moment(inl.date_of_expiry).format(
                                  "DD-MM-YYYY"
                                )}
                              </td>
                              {/* //adeel added no show count also */}
                              <td>{inl.sessions_taken + inl.no_show_count}</td>
                              <td>
                                <CurrencyFormat
                                  value={inl.net}
                                  displayType={"text"}
                                  thousandSpacing={"2s"}
                                  thousandSeparator={true}
                                  prefix={"Rs "}
                                  suffix={"/-"}
                                  renderText={(value) => <span>{value}</span>}
                                />
                              </td>
                              <td>
                                <CurrencyFormat
                                  value={inl.commissionPerSession}
                                  displayType={"text"}
                                  thousandSpacing={"2s"}
                                  thousandSeparator={true}
                                  prefix={"Rs "}
                                  suffix={"/-"}
                                  renderText={(value) => <span>{value}</span>}
                                />
                              </td>
                              <td>
                                {/* // adeel changed the formula for total commision added no show count also */}
                                <CurrencyFormat
                                  value={
                                    parseFloat(inl.sessions_taken + inl.no_show_count) *
                                    inl.commissionPerSession
                                  }
                                  displayType={"text"}
                                  thousandSpacing={"2s"}
                                  thousandSeparator={true}
                                  prefix={"Rs "}
                                  suffix={"/-"}
                                  renderText={(value) => <span>{value}</span>}
                                />
                                {/* //adeel changed the formula for total commision added no show count also */}
                              </td>
                              <td>{inl.no_show_count}</td>
                              <td>
                                {inl.order_status === 0 ||
                                  inl.contract_detail ===
                                  inl.sessions_taken + inl.no_show_count ||
                                  moment(inl.date_of_expiry).isBefore(moment()) ? ( //adeel added a check that if date of expiry is before the current date then it will show completed
                                  <span className="badge bg-success w-100">
                                    Completed
                                  </span>
                                ) : (
                                  <span className="badge bg-warning text-dark w-100">
                                    In Progress
                                  </span>
                                )}
                              </td>
                              <td>{inl.remarks}</td>
                            </tr>

                          ))}
                          {/* //adeel added total sessions and total commision */}
                          <tr>
                            <td colSpan="3"></td> {/* Empty space for first 2 columns */}
                            <td style={{
                              background: "rgb(252 239 239);",
                              color: "#000",
                              width: "13%",
                            }}>
                              Total Sessions:
                            </td>
                            <td>
                              {el.details?.reduce(
                                (acc, curr) =>
                                  acc + curr.sessions_taken + curr.no_show_count,
                                0
                              )}
                            </td>
                            <td colSpan="1"></td> {/* Empty space for the 5th column */}
                            <td style={{
                              background: "rgb(252 239 239);",
                              color: "#000",
                              width: "13%",
                            }}>
                              Total Commission:
                            </td>
                            <td>
                              {el.details?.reduce(
                                (acc, curr) =>
                                  acc + parseFloat(curr.sessions_taken + curr.no_show_count) *
                                  curr.commissionPerSession,
                                0
                              )}
                            </td>
                            <td colSpan="1"></td> {/* Empty space for remaining columns */}
                          </tr>
                        </td>
                      </tr>
                    ) : null}
                  </>
                ))
              ) : (
                <tr>
                  {/* //adeel added total sessions and total commision */}
                  <td colSpan="9" className="text-center text-capitalize">
                    No record found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              changePagination(e);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">Staff Commission</h5>
              {/* //adeel */}
              <button
                className="btn btn-primary d-inline-block float-end btn-sm"
                onClick={() => {
                  generatePdf(document.getElementById("report-POS"));
                }}
              >
                <i className="bi bi-file-earmark-pdf float-end"></i> PDF
              </button>
              <a
                href={exportLink}
                rel="noreferrer"
                // target="_blank"
                className="btn btn-primary mx-2 file-download float-end"
              >
                <i className="bi bi-download float-end" /> Excel
              </a>

              {/* //adeel  */}
            </div>
            <div className="card-body">
              <form className="row trainee-add" onSubmit={handleSubmit}>
                {/* <div className="col-md-2">
                  <div className="form-group custom-group">
                    Select Month
                    <select
                      className="form-select"
                      name="sdate"
                      value={values.sdate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="01">January</option>
                      <option value="02">February</option>
                      <option value="03">March</option>
                      <option value="04">April</option>
                      <option value="05">May</option>
                      <option value="06">June</option>
                      <option value="07">July</option>
                      <option value="08">August</option>
                      <option value="09">September</option>
                      <option value="10">October</option>
                      <option value="11">November</option>
                      <option value="12">December</option>
                    </select>
                    {errors.sdate && touched.sdate ? (
                      <p className="text-danger">{errors.sdate}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group custom-group">
                    Enter Year
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter End date"
                      name="edate"
                      value={values.edate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.edate && touched.edate ? (
                      <p className="text-danger">{errors.edate}</p>
                    ) : null}
                  </div>
                </div> */}
                <div className="col-md-2">
                  <div className="form-group custom-group">
                    Select Month
                    <select
                      className="form-select"
                      name="sdate"
                      value={values.sdate}
                      onChange={(e) => {
                        handleChange(e);
                        const newMonth = e.target.value;
                        const newPreviousMonth =
                          newMonth === "01"
                            ? "12"
                            : `0${parseInt(newMonth) - 1}`.slice(-2);
                        const newYear =
                          newMonth === "01"
                            ? parseInt(values.edate) - 1
                            : values.edate;
                        setFieldValue(
                          "startdate",
                          `${newYear}-${newPreviousMonth}-26`
                        );
                        setFieldValue(
                          "enddate",
                          `${values.edate}-${newMonth}-25`
                        );
                      }}
                      onBlur={handleBlur}
                    >
                      <option value="01">January</option>
                      <option value="02">February</option>
                      <option value="03">March</option>
                      <option value="04">April</option>
                      <option value="05">May</option>
                      <option value="06">June</option>
                      <option value="07">July</option>
                      <option value="08">August</option>
                      <option value="09">September</option>
                      <option value="10">October</option>
                      <option value="11">November</option>
                      <option value="12">December</option>
                    </select>
                    {errors.sdate && touched.sdate ? (
                      <p className="text-danger">{errors.sdate}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group custom-group">
                    Enter Year
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Year"
                      name="edate"
                      value={values.edate}
                      onChange={(e) => {
                        handleChange(e);
                        const newYear = e.target.value;
                        const newPreviousMonth =
                          values.sdate === "01"
                            ? "12"
                            : `0${parseInt(values.sdate) - 1}`.slice(-2);
                        const newStartDateYear =
                          values.sdate === "01"
                            ? parseInt(newYear) - 1
                            : newYear;
                        setFieldValue(
                          "startdate",
                          `${newStartDateYear}-${newPreviousMonth}-26`
                        );
                        setFieldValue(
                          "enddate",
                          `${newYear}-${values.sdate}-25`
                        );
                      }}
                      onBlur={handleBlur}
                    />
                    {errors.edate && touched.edate ? (
                      <p className="text-danger">{errors.edate}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group custom-group">
                    From
                    <input
                      type="date"
                      className="form-control"
                      placeholder="date"
                      name="startdate"
                      value={values.startdate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group custom-group">
                    To
                    <input
                      type="date"
                      className="form-control"
                      placeholder="date"
                      name="enddate"
                      value={values.enddate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>

                <div className="col-lg-2">
                  <div className="form-group custom-group">
                    Available Trainers
                    {trainerName ? (
                      <select
                        className="form-select"
                        name="trainer_id"
                        value={values.trainer_id || ""}
                        disabled={role === "9" && UId ? true : false}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      >
                        {trainerName.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.first_name} {el.last_name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                  </div>
                </div>
                {/* <div className="col-md-2">
                <div className="form-group custom-group">
                  From
                  <input
                    type="date"
                    className="form-control"
                    placeholder="date"
                    name="startdate"
                    value={values.sdate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                </div>
                <div className="col-md-2">
                <div className="form-group custom-group">
                  To
                  <input
                    type="date"
                    className="form-control"
                    placeholder="date"
                    name="enddate"
                    value={values.edate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                </div> */}
                <div className="col-12 col-md-1">
                  <button
                    type="submit"
                    className="btn btn-primary d-inline-block mt-4"
                  >
                    Generate
                  </button>
                </div>
                <div className="col-md-1 col-lg-1 mt-4 ms-auto">
                  <div className="input-group">
                    <select
                      className="form-select"
                      value={limit}
                      onChange={(e) => changeLimit(e.target.value)}
                    >
                      <option value="10">10</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="250">250</option>
                      <option value="500">500</option>
                    </select>
                  </div>
                </div>

                <div className=" col-md-2 text-end mt-4">
                  {/* <a
                    href={exportLink}
                    rel="noreferrer"
                    // target="_blank"
                    className="btn btn-primary mx-2 file-download"
                  >
                    <i className="bi bi-download" /> Excel
                  </a> */}
                </div>
              </form>
              <Table data={myData} rowsPerPage={limit} />
            </div>
          </div>
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
};

export default StaffCommision;
